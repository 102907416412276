const brandColors = {
    primary: '#150F96',
    primarySelect: '#0E0A63',
    disabled: '#BDBDD9',
    secondary: '#6D69C9',
    secondarySelect: '#524F96',
    tertiary: '#EBEBFA',
    welcomeCircles: '#150F96',
};

const systemColors = {
    error: '#FF4C5D',
    validation: '#7ACB00',
    toggleBullet: '#6C9A37',
    toggleBar: '#6C9A3766',
};

const mapping = {
    toggleBullet: '',
    toggleBar: '',
};

export default {
    colors: {
        ...brandColors,
        ...systemColors,
    },
    mapping,
};
